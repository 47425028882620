import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { ToastProvider } from './contexts/ToastContext';
import { AuthProvider } from './contexts/AuthContext';
import Login from './pages/auth/Login';
import Layout from './components/layout/Layout';
import Dashboard from './pages/dashboard/Dashboard';
import Settings from './pages/settings/Settings';
import Calculator from './pages/calculator/Calculator';
import EmployeeForm from './pages/employees/EmployeeForm';
import ProtectedRoute from './components/auth/ProtectedRoute';
import PermissionRoute from './components/auth/PermissionRoute';

// Debug Panel Component
const DebugPanel = () => {
  const token = localStorage.getItem('token');
  
  return (
    <div style={{
      position: 'fixed',
      bottom: '20px',
      left: '20px',
      background: 'white',
      padding: '15px',
      border: '1px solid #ccc',
      borderRadius: '4px',
      zIndex: 9999,
      maxWidth: '300px',
      boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
    }}>
      <h4 style={{ margin: '0 0 10px 0' }}>Debug Tools</h4>
      
      <div style={{ marginBottom: '10px' }}>
        <strong>Token:</strong> {token ? 'Present' : 'Missing'}
      </div>
      
      <div style={{ display: 'flex', flexDirection: 'column', gap: '5px' }}>
        <button 
          onClick={() => {
            localStorage.clear();
            window.location.href = '/login';
          }}
          style={styles.button}
        >
          Clear & Logout
        </button>
      </div>
    </div>
  );
};

function App() {
  console.log('App rendering');

  return (
    <Router>
      <AuthProvider>
        <ToastProvider>
          <Routes>
            {/* Public routes */}
            <Route path="/login" element={<Login />} />

            {/* Protected routes */}
            <Route element={<ProtectedRoute><Layout /></ProtectedRoute>}>
              {/* Default route */}
              <Route path="/" element={<Navigate to="/dashboard" replace />} />
              
              {/* Dashboard */}
              <Route path="/dashboard" element={<Dashboard />} />
              
              {/* Settings routes */}
              <Route path="/settings/*" element={<Settings />} />
              
              {/* Calculator route */}
              <Route path="/calculator" element={<Calculator />} />

              {/* Employee routes */}
              <Route path="/employees/new" element={
                <PermissionRoute requiredPermissions={['manageEmployee']}>
                  <EmployeeForm />
                </PermissionRoute>
              } />
              <Route path="/employees/edit/:id" element={
                <PermissionRoute requiredPermissions={['manageEmployee']}>
                  <EmployeeForm />
                </PermissionRoute>
              } />

              {/* Catch-all route */}
              <Route path="*" element={<Navigate to="/dashboard" replace />} />
            </Route>
          </Routes>

          {/* Always show debug panel in development */}
          {process.env.NODE_ENV === 'development' && <DebugPanel />}
        </ToastProvider>
      </AuthProvider>
    </Router>
  );
}

const styles = {
  button: {
    backgroundColor: '#ef4444',
    color: 'white',
    border: 'none',
    padding: '8px 12px',
    borderRadius: '4px',
    cursor: 'pointer',
    fontSize: '14px'
  }
};

export default App;
