import React, { useEffect } from 'react';
import { Link, Routes, Route, Navigate, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import PermissionRoute from '../../components/auth/PermissionRoute';
import Jobs from './Jobs';
import Positions from './Positions';
import Grades from './Grades';
import Levels from './Levels';
import BaseSalary from './BaseSalary';
import AdjustmentFactors from './AdjustmentFactors';
import Benefits from './Benefits';
import Multipliers from './Multipliers';
import UserManagement from './UserManagement';

const Settings = () => {
  const { user } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    console.log('Settings component mounted:', {
      pathname: location.pathname,
      user: {
        id: user?.id,
        email: user?.email,
        role: user?.role,
        isAdmin: user?.isAdmin,
        permissions: user?.permissions
      }
    });

    // Early return if no user
    if (!user) {
      console.log('No user found, redirecting to login');
      navigate('/login', { replace: true });
      return;
    }

    // Check permissions
    const hasSettingsAccess = user.isAdmin || user.permissions?.showSettings;
    console.log('Settings access check:', {
      isAdmin: user.isAdmin,
      showSettings: user.permissions?.showSettings,
      hasAccess: hasSettingsAccess
    });

    if (!hasSettingsAccess) {
      console.log('User lacks settings access, redirecting to dashboard');
      navigate('/dashboard', { replace: true });
      return;
    }

    // If at /settings, redirect to first available section
    if (location.pathname === '/settings') {
      const firstAvailableLink = getVisibleLinks()[0];
      if (firstAvailableLink) {
        navigate(firstAvailableLink.path, { replace: true });
      }
    }
  }, [location.pathname, user, navigate]);

  const baseSettingsLinks = [
    { path: 'jobs', label: 'Jobs', permission: 'showSettings' },
    { path: 'positions', label: 'Positions', permission: 'showSettings' },
    { path: 'grades', label: 'Grades', permission: 'showSettings' },
    { path: 'levels', label: 'Levels', permission: 'showSettings' },
    { path: 'base-salary', label: 'Base Salary', permission: 'showSettings' },
    { path: 'adjustment-factors', label: 'Adjustment Factors', permission: 'showSettings' },
    { path: 'benefits', label: 'Benefits', permission: 'showSettings' },
    { path: 'multipliers', label: 'Salary Multipliers', permission: 'showSettings' }
  ];

  // Add user management link for admins
  const settingsLinks = user?.isAdmin 
    ? [...baseSettingsLinks, { path: 'users', label: 'User Management', adminOnly: true }]
    : baseSettingsLinks;

  // Filter links based on permissions
  const getVisibleLinks = () => {
    return settingsLinks.filter(link => {
      if (link.adminOnly) return user?.isAdmin;
      if (link.permission) return user?.permissions?.[link.permission] || user?.isAdmin;
      return true;
    });
  };

  const visibleLinks = getVisibleLinks();

  console.log('Visible settings links:', {
    total: settingsLinks.length,
    visible: visibleLinks.length,
    links: visibleLinks.map(l => l.path)
  });

  // Early return if no user or no settings access
  if (!user || (!user.isAdmin && !user.permissions?.showSettings)) {
    return null;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">Settings</h1>
      
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-8">
        {visibleLinks.map(link => (
          <Link
            key={link.path}
            to={link.path}
            className={`bg-white p-4 rounded-lg shadow hover:shadow-md transition-shadow duration-200 text-center ${
              location.pathname.includes(link.path) ? 'ring-2 ring-blue-500' : ''
            }`}
          >
            <h2 className="text-lg font-medium text-gray-900">{link.label}</h2>
          </Link>
        ))}
      </div>

      <div className="bg-white rounded-lg shadow">
        <Routes>
          {visibleLinks.map(link => {
            let Component;
            switch (link.path) {
              case 'jobs':
                Component = Jobs;
                break;
              case 'positions':
                Component = Positions;
                break;
              case 'grades':
                Component = Grades;
                break;
              case 'levels':
                Component = Levels;
                break;
              case 'base-salary':
                Component = BaseSalary;
                break;
              case 'adjustment-factors':
                Component = AdjustmentFactors;
                break;
              case 'benefits':
                Component = Benefits;
                break;
              case 'multipliers':
                Component = Multipliers;
                break;
              case 'users':
                Component = UserManagement;
                break;
              default:
                return null;
            }

            return (
              <Route
                key={link.path}
                path={link.path}
                element={
                  link.adminOnly ? (
                    <PermissionRoute requireAdmin={true}>
                      <Component />
                    </PermissionRoute>
                  ) : (
                    <PermissionRoute requiredPermissions={['showSettings']}>
                      <Component />
                    </PermissionRoute>
                  )
                }
              />
            );
          })}
          <Route
            path="*"
            element={<Navigate to={visibleLinks[0]?.path || '/dashboard'} replace />}
          />
        </Routes>
      </div>

      {process.env.NODE_ENV === 'development' && (
        <div className="fixed bottom-4 right-4 bg-white p-4 rounded shadow text-xs">
          <div>Path: {location.pathname}</div>
          <div>Admin: {user.isAdmin ? 'Yes' : 'No'}</div>
          <div>Permissions: {JSON.stringify(user.permissions)}</div>
        </div>
      )}
    </div>
  );
};

export default Settings;
