import React, { useState, useEffect } from 'react';
import { Plus, Edit, Trash } from 'lucide-react';
import { settingsAPI } from '../../services/api';
import { useToast } from '../../contexts/ToastContext';

const Multipliers = () => {
  const [baseMultipliers, setBaseMultipliers] = useState({
    experienceMultiplier: '',
    loyaltyMultiplier: '',
    baseProjectMultiplier: '', // renamed from offshoreMultiplier
    actingAsMultiplier: '',
    pillarMultiplier: '',
    remoteMultiplier: ''
  });
  const [customMultipliers, setCustomMultipliers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [editingMultiplier, setEditingMultiplier] = useState(null);
  const [formData, setFormData] = useState({
    title: '',
    multiplier: ''
  });
  const { showToast } = useToast();

  useEffect(() => {
    fetchMultipliers();
  }, []);

  const fetchMultipliers = async () => {
    try {
      setLoading(true);
      const response = await settingsAPI.get();
      const data = response.data.data;
      
      if (!data) {
        throw new Error('No settings data received');
      }

      // Set base multipliers
      setBaseMultipliers({
        experienceMultiplier: data.experienceMultiplier || '',
        loyaltyMultiplier: data.loyaltyMultiplier || '',
        baseProjectMultiplier: data.offshoreMultiplier || '', // using old key for compatibility
        actingAsMultiplier: data.actingAsMultiplier || '',
        pillarMultiplier: data.pillarMultiplier || '',
        remoteMultiplier: data.remoteMultiplier || ''
      });

      // Set custom multipliers
      if (data.customMultipliers) {
        const parsedMultipliers = typeof data.customMultipliers === 'string' 
          ? JSON.parse(data.customMultipliers) 
          : data.customMultipliers;
        setCustomMultipliers(parsedMultipliers);
      }
    } catch (error) {
      console.error('Error fetching multipliers:', error);
      showToast('Failed to fetch multipliers', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      setLoading(true);
      const payload = {
        ...baseMultipliers,
        offshoreMultiplier: baseMultipliers.baseProjectMultiplier, // map new name back to old key
        customMultipliers: JSON.stringify(customMultipliers)
      };
      await settingsAPI.update(payload);
      showToast('Multipliers saved successfully');
    } catch (error) {
      console.error('Error saving multipliers:', error);
      showToast('Failed to save multipliers', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleBaseMultiplierChange = (field, value) => {
    setBaseMultipliers(prev => ({
      ...prev,
      [field]: value
    }));
  };

  const handleCustomMultiplierSubmit = (e) => {
    e.preventDefault();
    const newMultiplier = {
      id: editingMultiplier ? editingMultiplier.id : Date.now(),
      title: formData.title,
      multiplier: parseFloat(formData.multiplier)
    };

    if (editingMultiplier) {
      setCustomMultipliers(prev => 
        prev.map(m => m.id === editingMultiplier.id ? newMultiplier : m)
      );
    } else {
      setCustomMultipliers(prev => [...prev, newMultiplier]);
    }

    setFormData({ title: '', multiplier: '' });
    setEditingMultiplier(null);
    setShowModal(false);
  };

  const handleDeleteCustomMultiplier = (id) => {
    if (window.confirm('Are you sure you want to delete this multiplier?')) {
      setCustomMultipliers(prev => prev.filter(m => m.id !== id));
    }
  };

  const baseMultiplierFields = [
    { key: 'experienceMultiplier', label: 'Experience Years Multiplier' },
    { key: 'loyaltyMultiplier', label: 'Years with Company Multiplier' },
    { key: 'baseProjectMultiplier', label: 'Base on Project Multiplier' },
    { key: 'actingAsMultiplier', label: 'Acting as Different Grade Multiplier' },
    { key: 'pillarMultiplier', label: 'Pillar Multiplier' },
    { key: 'remoteMultiplier', label: 'Remote Work Multiplier' }
  ];

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Salary Multipliers</h1>
        <div className="space-x-4">
          <button
            onClick={() => {
              setEditingMultiplier(null);
              setFormData({ title: '', multiplier: '' });
              setShowModal(true);
            }}
            className="bg-green-500 text-white px-4 py-2 rounded hover:bg-green-600"
          >
            <Plus size={16} className="inline mr-2" />
            Add New Multiplier
          </button>
          <button
            onClick={handleSave}
            disabled={loading}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
          >
            Save All Changes
          </button>
        </div>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Base Multipliers</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {baseMultiplierFields.map(field => (
            <div key={field.key}>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                {field.label}
              </label>
              <input
                type="number"
                value={baseMultipliers[field.key]}
                onChange={(e) => handleBaseMultiplierChange(field.key, e.target.value)}
                className="w-full border rounded-md p-2"
                placeholder="Enter multiplier value"
                step="0.01"
                min="0"
              />
              <p className="mt-1 text-sm text-gray-500">
                {field.key === 'experienceMultiplier' && 'Applied per year of experience'}
                {field.key === 'loyaltyMultiplier' && 'Applied per year with the company'}
                {field.key === 'baseProjectMultiplier' && 'Applied based on project assignment'}
                {field.key === 'actingAsMultiplier' && 'Applied when acting in a different grade'}
                {field.key === 'pillarMultiplier' && 'Applied for pillar employees'}
                {field.key === 'remoteMultiplier' && 'Applied for remote workers'}
              </p>
            </div>
          ))}
        </div>

        <div className="mt-8">
          <h2 className="text-xl font-semibold mb-4">Custom Multipliers</h2>
          {customMultipliers.length === 0 ? (
            <p className="text-gray-500">No custom multipliers added yet.</p>
          ) : (
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {customMultipliers.map(multiplier => (
                <div key={multiplier.id} className="border rounded-lg p-4">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="font-medium">{multiplier.title}</h3>
                      <p className="text-gray-600">Multiplier: {multiplier.multiplier}</p>
                    </div>
                    <div className="space-x-2">
                      <button
                        onClick={() => {
                          setEditingMultiplier(multiplier);
                          setFormData({
                            title: multiplier.title,
                            multiplier: multiplier.multiplier
                          });
                          setShowModal(true);
                        }}
                        className="text-blue-600 hover:text-blue-800"
                      >
                        <Edit size={16} />
                      </button>
                      <button
                        onClick={() => handleDeleteCustomMultiplier(multiplier.id)}
                        className="text-red-600 hover:text-red-800"
                      >
                        <Trash size={16} />
                      </button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        <div className="mt-6 p-4 bg-gray-50 rounded">
          <h3 className="text-lg font-medium mb-2">How Multipliers Work</h3>
          <ul className="list-disc list-inside space-y-2 text-gray-600">
            <li>Experience Multiplier: Increases salary based on total years of experience</li>
            <li>Loyalty Multiplier: Additional increase based on years with the company</li>
            <li>Base on Project Multiplier: Applied based on project assignment</li>
            <li>Acting As Multiplier: Applied when employee acts in a different grade</li>
            <li>Pillar Multiplier: Applied for employees designated as pillars</li>
            <li>Remote Multiplier: Applied for employees working remotely</li>
            <li>Custom Multipliers: Additional multipliers that can be defined as needed</li>
          </ul>
        </div>
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h3 className="text-lg font-semibold mb-4">
              {editingMultiplier ? 'Edit Multiplier' : 'Add New Multiplier'}
            </h3>
            <form onSubmit={handleCustomMultiplierSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  className="w-full border rounded-md p-2"
                  required
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Multiplier Value
                </label>
                <input
                  type="number"
                  value={formData.multiplier}
                  onChange={(e) => setFormData({ ...formData, multiplier: e.target.value })}
                  className="w-full border rounded-md p-2"
                  step="0.01"
                  min="0"
                  required
                />
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => {
                    setShowModal(false);
                    setEditingMultiplier(null);
                    setFormData({ title: '', multiplier: '' });
                  }}
                  className="px-4 py-2 border rounded-md hover:bg-gray-50"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  {editingMultiplier ? 'Update' : 'Add'} Multiplier
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Multipliers;
