import React, { useState, useEffect } from 'react';
import { settingsAPI } from '../../services/api';
import { useToast } from '../../contexts/ToastContext';

const Benefits = () => {
  const [settings, setSettings] = useState(null);
  const [benefits, setBenefits] = useState([]);
  const [newBenefit, setNewBenefit] = useState({ name: '', amount: '' });
  const [loading, setLoading] = useState(false);
  const { showToast } = useToast();

  useEffect(() => {
    fetchSettings();
  }, []);

  const fetchSettings = async () => {
    try {
      setLoading(true);
      const response = await settingsAPI.get();
      const settingsData = response.data.data;

      if (!settingsData) {
        throw new Error('No settings data received');
      }

      setSettings(settingsData);
      
      // Parse benefits from JSON if needed
      const parsedBenefits = typeof settingsData.benefits === 'string'
        ? JSON.parse(settingsData.benefits)
        : settingsData.benefits || [];
      
      setBenefits(parsedBenefits);
    } catch (error) {
      console.error('Error fetching settings:', error);
      showToast('Failed to fetch settings', 'error');
    } finally {
      setLoading(false);
    }
  };

  const handleAddBenefit = async () => {
    if (!newBenefit.name || !newBenefit.amount) return;

    try {
      setLoading(true);
      const updatedBenefits = [...benefits, { ...newBenefit, amount: Number(newBenefit.amount) }];
      
      const response = await settingsAPI.update({
        ...settings,
        benefits: JSON.stringify(updatedBenefits)
      });

      if (response.data.data) {
        const parsedBenefits = typeof response.data.data.benefits === 'string'
          ? JSON.parse(response.data.data.benefits)
          : response.data.data.benefits;
        
        setBenefits(parsedBenefits);
        setNewBenefit({ name: '', amount: '' });
        showToast('Benefit added successfully');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error adding benefit:', error);
      showToast(error.response?.data?.message || 'Failed to add benefit', 'error');
      // Refresh settings to ensure UI is in sync with server
      fetchSettings();
    } finally {
      setLoading(false);
    }
  };

  const handleRemoveBenefit = async (index) => {
    try {
      setLoading(true);
      const updatedBenefits = benefits.filter((_, i) => i !== index);
      
      const response = await settingsAPI.update({
        ...settings,
        benefits: JSON.stringify(updatedBenefits)
      });

      if (response.data.data) {
        const parsedBenefits = typeof response.data.data.benefits === 'string'
          ? JSON.parse(response.data.data.benefits)
          : response.data.data.benefits;
        
        setBenefits(parsedBenefits);
        showToast('Benefit removed successfully');
      } else {
        throw new Error('Invalid response from server');
      }
    } catch (error) {
      console.error('Error removing benefit:', error);
      showToast(error.response?.data?.message || 'Failed to remove benefit', 'error');
      // Refresh settings to ensure UI is in sync with server
      fetchSettings();
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Benefits</h1>
      </div>

      <div className="bg-white rounded-lg shadow p-6">
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-4">Add New Benefit</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Benefit Name
              </label>
              <input
                type="text"
                value={newBenefit.name}
                onChange={(e) => setNewBenefit(prev => ({ ...prev, name: e.target.value }))}
                className="w-full border rounded-md p-2"
                placeholder="Enter benefit name"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Amount
              </label>
              <div className="relative">
                <span className="absolute left-3 top-2 text-gray-500">$</span>
                <input
                  type="number"
                  value={newBenefit.amount}
                  onChange={(e) => setNewBenefit(prev => ({ ...prev, amount: e.target.value }))}
                  className="w-full border rounded-md p-2 pl-6"
                  placeholder="Enter amount"
                  step="0.01"
                  min="0"
                />
              </div>
            </div>
          </div>
          <button
            onClick={handleAddBenefit}
            disabled={!newBenefit.name || !newBenefit.amount || loading}
            className="mt-4 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 disabled:opacity-50"
          >
            Add Benefit
          </button>
        </div>

        <div>
          <h2 className="text-lg font-semibold mb-4">Current Benefits</h2>
          <div className="space-y-4">
            {benefits.map((benefit, index) => (
              <div key={index} className="flex items-center justify-between bg-gray-50 p-4 rounded">
                <div>
                  <h3 className="font-medium">{benefit.name}</h3>
                  <p className="text-gray-600">${Number(benefit.amount).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</p>
                </div>
                <button
                  onClick={() => handleRemoveBenefit(index)}
                  disabled={loading}
                  className="text-red-600 hover:text-red-900 disabled:opacity-50"
                >
                  Remove
                </button>
              </div>
            ))}
            {benefits.length === 0 && (
              <p className="text-gray-500 text-center py-4">No benefits added yet</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Benefits;
