import React, { useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const Sidebar = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { user, logout } = useAuth();

    useEffect(() => {
        console.log('Sidebar rendering with:', {
            pathname: location.pathname,
            user: {
                id: user?.id,
                email: user?.email,
                role: user?.role,
                isAdmin: user?.isAdmin
            }
        });
    }, [location.pathname, user]);

    const isActive = (path) => {
        return location.pathname.startsWith(path) ? 'bg-blue-700' : '';
    };

    const handleLogout = () => {
        console.log('Logging out user');
        logout();
        navigate('/login', { replace: true });
    };

    // Navigation items with conditional settings based on permissions
    const getNavItems = () => {
        const items = [
            {
                path: '/dashboard',
                label: 'Employees',
                icon: '👥'
            },
            {
                path: '/calculator',
                label: 'Salary Calculator',
                icon: '🧮'
            }
        ];

        // Only show settings if user has permission or is admin
        if (user?.isAdmin || user?.permissions?.showSettings) {
            items.push({
                path: '/settings',
                label: 'Settings',
                icon: '⚙️'
            });
        }

        return items;
    };

    return (
        <div className="w-64 bg-blue-600 text-white min-h-screen flex flex-col">
            <div className="p-4 border-b border-blue-500">
                <h1 className="text-2xl font-bold">Salary System</h1>
                {user && (
                    <div className="mt-2 text-sm opacity-75">
                        <div>{user.email}</div>
                        <div>{user.role}</div>
                    </div>
                )}
            </div>

            <nav className="flex-1 py-4">
                {getNavItems().map(item => (
                    <Link 
                        key={item.path}
                        to={item.path} 
                        className={`flex items-center px-4 py-3 hover:bg-blue-700 transition-colors duration-150 ${isActive(item.path)}`}
                    >
                        <span className="mr-3">{item.icon}</span>
                        <span>{item.label}</span>
                    </Link>
                ))}
            </nav>

            <div className="p-4 border-t border-blue-500">
                <button 
                    onClick={handleLogout}
                    className="w-full px-4 py-2 text-left hover:bg-blue-700 rounded transition-colors duration-150 flex items-center"
                >
                    <span className="mr-3">🚪</span>
                    <span>Logout</span>
                </button>
            </div>

            {process.env.NODE_ENV === 'development' && (
                <div className="p-2 bg-blue-800 text-xs">
                    <div>Path: {location.pathname}</div>
                    <div>Role: {user?.role}</div>
                </div>
            )}
        </div>
    );
};

// Prevent unnecessary re-renders
export default React.memo(Sidebar, (prevProps, nextProps) => {
    // Only re-render if props actually change
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
