import React, { createContext, useContext, useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { authAPI } from '../services/api';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        checkAuth();
    }, []);

    const checkAuth = async () => {
        try {
            const token = localStorage.getItem('token');
            if (!token) {
                console.log('No token found, skipping auth check');
                setLoading(false);
                return;
            }

            console.log('Checking auth with token');
            const response = await authAPI.me();
            console.log('Auth check response:', {
                status: response.status,
                data: {
                    user: {
                        id: response.data.user?.id,
                        email: response.data.user?.email,
                        role: response.data.user?.role,
                        isAdmin: response.data.user?.isAdmin,
                        permissions: response.data.user?.permissions
                    }
                }
            });

            if (response.data.user) {
                const permissions = response.data.user.permissions || {};
                console.log('Received permissions:', permissions);

                const userData = {
                    ...response.data.user,
                    permissions: {
                        showSettings: permissions.showSettings || false,
                        manageSettings: permissions.manageSettings || false,
                        showSalary: permissions.showSalary || false,
                        showCost: permissions.showCost || false,
                        manageEmployee: permissions.manageEmployee || false,
                        deleteEmployee: permissions.deleteEmployee || false
                    }
                };

                console.log('Setting user data:', userData);
                setUser(userData);

                // Check if current location requires permissions
                if (location.pathname.startsWith('/settings') && 
                    !userData.isAdmin && 
                    !userData.permissions.showSettings) {
                    console.log('Settings access denied, redirecting to dashboard');
                    navigate('/dashboard', { replace: true });
                }
            } else {
                console.log('No user data in response');
                localStorage.removeItem('token');
                navigate('/login', { replace: true });
            }
        } catch (error) {
            console.error('Auth check error:', {
                message: error.message,
                response: error.response?.data,
                status: error.response?.status
            });
            localStorage.removeItem('token');
            navigate('/login', { replace: true });
        } finally {
            setLoading(false);
        }
    };

    const login = async (credentials) => {
        try {
            console.log('Login attempt with:', { email: credentials.email });
            const response = await authAPI.login(credentials);
            console.log('Login response:', {
                hasToken: !!response.token,
                user: {
                    id: response.user?.id,
                    email: response.user?.email,
                    role: response.user?.role,
                    isAdmin: response.user?.isAdmin,
                    permissions: response.user?.permissions
                }
            });

            if (!response.token) {
                throw new Error('No token received in login response');
            }

            if (!response.user) {
                throw new Error('No user data received in login response');
            }

            localStorage.setItem('token', response.token);
            const permissions = response.user.permissions || {};
            console.log('Received permissions:', permissions);

            const userData = {
                ...response.user,
                permissions: {
                    showSettings: permissions.showSettings || false,
                    manageSettings: permissions.manageSettings || false,
                    showSalary: permissions.showSalary || false,
                    showCost: permissions.showCost || false,
                    manageEmployee: permissions.manageEmployee || false,
                    deleteEmployee: permissions.deleteEmployee || false
                }
            };

            console.log('Setting user data after login:', userData);
            setUser(userData);

            // Navigate to appropriate page based on permissions
            const from = location.state?.from?.pathname || '/dashboard';
            if (from.startsWith('/settings') && !userData.isAdmin && !userData.permissions.showSettings) {
                navigate('/dashboard', { replace: true });
            } else {
                navigate(from, { replace: true });
            }

            return response;
        } catch (error) {
            console.error('Login error in AuthContext:', {
                message: error.message,
                response: {
                    status: error.response?.status,
                    data: error.response?.data
                }
            });
            throw error;
        }
    };

    const changePassword = async (currentPassword, newPassword) => {
        try {
            const response = await authAPI.changePassword({ currentPassword, newPassword });
            return response.data;
        } catch (error) {
            console.error('Change password error:', error);
            throw error;
        }
    };

    const logout = () => {
        console.log('Logging out user');
        localStorage.removeItem('token');
        setUser(null);
        navigate('/login', { replace: true });
    };

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="text-center">
                    <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-blue-600 mx-auto"></div>
                    <p className="mt-2 text-gray-600">Loading...</p>
                </div>
            </div>
        );
    }

    const value = {
        user,
        login,
        logout,
        loading,
        checkAuth,
        changePassword
    };

    console.log('AuthContext value:', {
        hasUser: !!user,
        userRole: user?.role,
        isAdmin: user?.isAdmin,
        permissions: user?.permissions,
        currentPath: location.pathname
    });

    return (
        <AuthContext.Provider value={value}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (!context) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
