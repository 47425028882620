import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const ProtectedRoute = ({ children }) => {
    const { user } = useAuth();
    const location = useLocation();

    useEffect(() => {
        console.log('ProtectedRoute check:', {
            pathname: location.pathname,
            user: {
                id: user?.id,
                email: user?.email,
                role: user?.role,
                isAdmin: user?.isAdmin,
                permissions: user?.permissions
            }
        });
    }, [location.pathname, user]);

    // Check if user is authenticated
    if (!user) {
        console.log('No user found in ProtectedRoute, redirecting to login');
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Check if trying to access settings without permission
    if (location.pathname.startsWith('/settings') && 
        !user.isAdmin && 
        !user.permissions?.showSettings) {
        console.log('Settings access denied:', {
            path: location.pathname,
            isAdmin: user.isAdmin,
            showSettings: user.permissions?.showSettings
        });
        return <Navigate to="/dashboard" replace />;
    }

    // Check if trying to access employees without permission
    if (location.pathname.startsWith('/employees') && 
        !user.isAdmin && 
        !user.permissions?.showSalary) {
        console.log('Employees access denied:', {
            path: location.pathname,
            isAdmin: user.isAdmin,
            showSalary: user.permissions?.showSalary
        });
        return <Navigate to="/dashboard" replace />;
    }

    // Check if trying to access employee management without permission
    if ((location.pathname.includes('/employees/new') || 
         location.pathname.includes('/employees/edit')) && 
        !user.isAdmin && 
        !user.permissions?.manageEmployee) {
        console.log('Employee management access denied:', {
            path: location.pathname,
            isAdmin: user.isAdmin,
            manageEmployee: user.permissions?.manageEmployee
        });
        return <Navigate to="/employees" replace />;
    }

    // Log successful access
    console.log('Access granted:', {
        path: location.pathname,
        user: {
            isAdmin: user.isAdmin,
            permissions: user.permissions
        }
    });

    return children;
};

// Prevent unnecessary re-renders
export default React.memo(ProtectedRoute, (prevProps, nextProps) => {
    // Only re-render if children change
    return prevProps.children === nextProps.children;
});
