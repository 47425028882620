import React, { useEffect } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import { useAuth } from '../../contexts/AuthContext';

const Layout = () => {
    const { user } = useAuth();
    const location = useLocation();

    useEffect(() => {
        console.log('Layout rendering with:', {
            pathname: location.pathname,
            user: {
                id: user?.id,
                email: user?.email,
                role: user?.role,
                isAdmin: user?.isAdmin,
                permissions: user?.permissions
            }
        });
    }, [location.pathname, user]);

    // Check if user is authenticated
    if (!user) {
        console.log('No user found in Layout, redirecting to login');
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Check if trying to access settings without permission
    if (location.pathname.startsWith('/settings') && 
        !user.isAdmin && 
        !user.permissions?.showSettings) {
        console.log('Settings access denied:', {
            path: location.pathname,
            isAdmin: user.isAdmin,
            showSettings: user.permissions?.showSettings
        });
        return <Navigate to="/dashboard" replace />;
    }

    // Check if trying to access employees without permission
    if (location.pathname.startsWith('/employees') && 
        !user.isAdmin && 
        !user.permissions?.showSalary) {
        console.log('Employees access denied:', {
            path: location.pathname,
            isAdmin: user.isAdmin,
            showSalary: user.permissions?.showSalary
        });
        return <Navigate to="/dashboard" replace />;
    }

    return (
        <div className="flex h-screen bg-gray-100">
            <Sidebar />
            <div className="flex-1 overflow-auto">
                <main className="p-6">
                    {process.env.NODE_ENV === 'development' && (
                        <div id="debug-info" className="fixed top-0 right-0 bg-white p-2 text-xs z-50">
                            <div>Path: {location.pathname}</div>
                            <div>User: {user.email}</div>
                            <div>Admin: {user.isAdmin ? 'Yes' : 'No'}</div>
                            <div>Role: {user.role}</div>
                            <div>Permissions: {JSON.stringify(user.permissions)}</div>
                        </div>
                    )}
                    <Outlet />
                </main>
            </div>
        </div>
    );
};

// Prevent unnecessary re-renders
export default React.memo(Layout, (prevProps, nextProps) => {
    // Only re-render if props actually change
    return JSON.stringify(prevProps) === JSON.stringify(nextProps);
});
