import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import { authAPI } from '../../services/api';
import { toast } from 'react-hot-toast';

const UserManagement = () => {
  const { user: currentUser } = useAuth();
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    name: '',
    password: '',
    permissions: {
      showSettings: false,
      manageSettings: false,
      showSalary: false,
      showCost: false,
      manageEmployee: false,
      deleteEmployee: false
    }
  });

  useEffect(() => {
    loadUsers();
  }, []);

  const loadUsers = async () => {
    try {
      setLoading(true);
      const response = await authAPI.listUsers();
      console.log('Users API Response:', response);
      
      // Extract users from response.data.users
      const usersData = response.data?.users || [];
      console.log('Processed users data:', usersData);
      setUsers(usersData);
    } catch (error) {
      console.error('Failed to load users:', error);
      toast.error('Failed to load users');
    } finally {
      setLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (name.startsWith('perm_')) {
      const permName = name.replace('perm_', '');
      setFormData(prev => ({
        ...prev,
        permissions: {
          ...prev.permissions,
          [permName]: checked
        }
      }));
    } else {
      setFormData(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      if (selectedUser) {
        await authAPI.updateUser(selectedUser.id, {
          name: formData.name,
          permissions: formData.permissions
        });
        toast.success('User updated successfully');
      } else {
        await authAPI.createUser({
          email: formData.email,
          name: formData.name,
          password: formData.password,
          permissions: formData.permissions
        });
        toast.success('User created successfully');
      }
      loadUsers();
      resetForm();
    } catch (error) {
      console.error('Operation failed:', error);
      toast.error(error.response?.data?.message || 'Operation failed');
    } finally {
      setLoading(false);
    }
  };

  const handleDelete = async (userId) => {
    if (window.confirm('Are you sure you want to delete this user?')) {
      try {
        setLoading(true);
        await authAPI.deleteUser(userId);
        toast.success('User deleted successfully');
        loadUsers();
      } catch (error) {
        console.error('Failed to delete user:', error);
        toast.error(error.response?.data?.message || 'Failed to delete user');
      } finally {
        setLoading(false);
      }
    }
  };

  const handleEdit = (user) => {
    setSelectedUser(user);
    setFormData({
      email: user.email,
      name: user.name,
      password: '',
      permissions: user.permissions || {
        showSettings: false,
        manageSettings: false,
        showSalary: false,
        showCost: false,
        manageEmployee: false,
        deleteEmployee: false
      }
    });
  };

  const resetForm = () => {
    setSelectedUser(null);
    setFormData({
      email: '',
      name: '',
      password: '',
      permissions: {
        showSettings: false,
        manageSettings: false,
        showSalary: false,
        showCost: false,
        manageEmployee: false,
        deleteEmployee: false
      }
    });
  };

  if (!currentUser?.isAdmin) {
    return <div>Access denied</div>;
  }

  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold mb-6">User Management</h1>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        {/* User Form */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-6">
            {selectedUser ? 'Edit User' : 'Create New User'}
          </h2>
          <form onSubmit={handleSubmit} className="space-y-6">
            {!selectedUser && (
              <>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Email</label>
                  <input
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    className="w-full border rounded-md p-2"
                    required={!selectedUser}
                  />
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">Password</label>
                  <input
                    type="password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    className="w-full border rounded-md p-2"
                    required={!selectedUser}
                  />
                </div>
              </>
            )}
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">Name</label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                className="w-full border rounded-md p-2"
                required
              />
            </div>

            <div className="space-y-4">
              <label className="block text-sm font-medium text-gray-700">Permissions</label>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 bg-gray-50 p-4 rounded-lg">
                <div className="space-y-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="perm_showSettings"
                      checked={formData.permissions.showSettings}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-900">Show Settings</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="perm_manageSettings"
                      checked={formData.permissions.manageSettings}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-900">Edit/Delete Settings</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="perm_showSalary"
                      checked={formData.permissions.showSalary}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-900">Show Calculated Salary</span>
                  </label>
                </div>
                <div className="space-y-4">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="perm_showCost"
                      checked={formData.permissions.showCost}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-900">Show Accumulated Cost</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="perm_manageEmployee"
                      checked={formData.permissions.manageEmployee}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-900">Edit Employee</span>
                  </label>
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      name="perm_deleteEmployee"
                      checked={formData.permissions.deleteEmployee}
                      onChange={handleInputChange}
                      className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                    />
                    <span className="ml-2 text-sm text-gray-900">Delete Employee</span>
                  </label>
                </div>
              </div>
            </div>

            <div className="flex space-x-3">
              <button
                type="submit"
                disabled={loading}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                {loading ? (selectedUser ? 'Updating...' : 'Creating...') : (selectedUser ? 'Update' : 'Create')}
              </button>
              {selectedUser && (
                <button
                  type="button"
                  onClick={resetForm}
                  disabled={loading}
                  className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2 disabled:opacity-50"
                >
                  Cancel
                </button>
              )}
            </div>
          </form>
        </div>

        {/* Users List */}
        <div className="bg-white rounded-lg shadow p-6">
          <h2 className="text-xl font-semibold mb-6">Users</h2>
          <div className="space-y-4">
            {loading ? (
              <div className="text-center text-gray-500 py-4">Loading users...</div>
            ) : users.length > 0 ? (
              users.map(user => (
                <div key={user.id} className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                  <div className="flex justify-between items-start">
                    <div>
                      <h3 className="text-lg font-medium text-gray-900">{user.name}</h3>
                      <p className="text-sm text-gray-500">{user.email}</p>
                      <div className="mt-3">
                        <p className="text-sm font-medium text-gray-700 mb-2">Permissions:</p>
                        <div className="grid grid-cols-2 gap-2">
                          {Object.entries(user.permissions || {}).map(([key, value]) => (
                            value && (
                              <div key={key} className="flex items-center text-sm text-gray-600">
                                <svg className="h-4 w-4 text-green-500 mr-2" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" stroke="currentColor">
                                  <path d="M5 13l4 4L19 7"></path>
                                </svg>
                                {key.replace(/([A-Z])/g, ' $1').toLowerCase()}
                              </div>
                            )
                          ))}
                        </div>
                      </div>
                    </div>
                    {!user.isAdmin && (
                      <div className="flex space-x-3">
                        <button
                          onClick={() => handleEdit(user)}
                          disabled={loading}
                          className="text-blue-600 hover:text-blue-900 font-medium"
                        >
                          Edit
                        </button>
                        <button
                          onClick={() => handleDelete(user.id)}
                          disabled={loading}
                          className="text-red-600 hover:text-red-900 font-medium"
                        >
                          Delete
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              ))
            ) : (
              <div className="text-center text-gray-500 py-4">No users found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserManagement;
