import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:3000/api';

// Create axios instance with default config
const api = axios.create({
    baseURL: API_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
    timeout: parseInt(process.env.REACT_APP_API_TIMEOUT) || 10000
});

// Debug configuration
console.log('API Configuration:', {
    baseURL: API_URL,
    withCredentials: true,
    timeout: api.defaults.timeout,
    environment: process.env.NODE_ENV,
    debug: process.env.REACT_APP_DEBUG
});

// Add token to requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    
    // Log request for debugging
    console.log('API Request:', {
        url: config.url,
        method: config.method,
        headers: {
            ...config.headers,
            Authorization: config.headers.Authorization ? 'Bearer [token]' : 'None'
        },
        data: config.data,
        timestamp: new Date().toISOString()
    });
    
    return config;
  },
  (error) => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Handle response errors
api.interceptors.response.use(
  (response) => {
    // Log successful response for debugging
    console.log('API Response:', {
        url: response.config.url,
        method: response.config.method,
        status: response.status,
        data: response.data,
        timestamp: new Date().toISOString()
    });
    return response;
  },
  (error) => {
    // Log detailed error information
    console.error('API Error:', {
      url: error.config?.url,
      method: error.config?.method,
      status: error.response?.status,
      statusText: error.response?.statusText,
      data: error.response?.data,
      message: error.message,
      stack: process.env.NODE_ENV === 'development' ? error.stack : undefined,
      timestamp: new Date().toISOString()
    });

    // Handle specific error cases
    if (error.response) {
      switch (error.response.status) {
        case 401:
          console.log('Authentication error, clearing token');
          localStorage.removeItem('token');
          if (!error.config.url.includes('/auth/login')) {
            console.log('Redirecting to login page');
            window.location.href = '/login';
          }
          break;
        case 403:
          console.error('Permission denied:', {
            url: error.config?.url,
            method: error.config?.method,
            data: error.response?.data
          });
          break;
        case 404:
          console.error('Resource not found:', {
            url: error.config?.url,
            method: error.config?.method
          });
          break;
        case 500:
          console.error('Server error:', {
            url: error.config?.url,
            method: error.config?.method,
            data: error.response?.data
          });
          break;
        default:
          console.error('Request failed:', {
            url: error.config?.url,
            method: error.config?.method,
            status: error.response?.status,
            data: error.response?.data
          });
      }
    } else if (error.request) {
      console.error('No response received:', {
        request: error.request,
        message: 'The request was made but no response was received',
        timestamp: new Date().toISOString()
      });
    } else {
      console.error('Request setup failed:', {
        message: error.message,
        config: error.config,
        timestamp: new Date().toISOString()
      });
    }

    return Promise.reject(error);
  }
);

export const authAPI = {
  login: async (credentials) => {
    try {
      console.log('Attempting login with:', { email: credentials.email });
      const response = await api.post('/auth/login', credentials);
      console.log('Login response:', {
        status: response.status,
        hasToken: !!response.data.token,
        hasUser: !!response.data.user,
        userPermissions: response.data.user?.permissions
      });
      return response.data;
    } catch (error) {
      console.error('Login error:', {
        status: error.response?.status,
        data: error.response?.data,
        message: error.message
      });
      throw error;
    }
  },
  me: () => api.get('/auth/me'),
  changePassword: (data) => api.post('/auth/change-password', data),
  createUser: (data) => api.post('/auth/users', data),
  updateUser: (userId, data) => api.put(`/auth/users/${userId}`, data),
  deleteUser: (userId) => api.delete(`/auth/users/${userId}`),
  listUsers: () => api.get('/auth/users'),
};

export const employeesAPI = {
  list: (params) => api.get('/employees', { params }),
  get: (id) => api.get(`/employees/${id}`),
  create: (data) => api.post('/employees', data),
  update: (id, data) => api.put(`/employees/${id}`, data),
  delete: (id) => api.delete(`/employees/${id}`),
  import: (file) => {
    const formData = new FormData();
    formData.append('file', file);
    return api.post('/employees/import', formData, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  downloadTemplate: () => api.get('/employees/template/download', { responseType: 'blob' }),
  export: () => api.get('/employees/export', { responseType: 'blob' }),
};

export const calculatorAPI = {
  calculate: (data) => api.post('/calculator', data),
};

// Jobs API (using technologies endpoint for backward compatibility)
export const jobsAPI = {
  list: () => api.get('/technologies'),
  create: (data) => api.post('/technologies', data),
  update: (id, data) => api.put(`/technologies/${id}`, data),
  delete: (id) => api.delete(`/technologies/${id}`),
};

// Positions API (using specializations endpoint for backward compatibility)
export const positionsAPI = {
  list: (jobId) => api.get('/specializations', { params: { technologyId: jobId } }),
  create: (data) => api.post('/specializations', data),
  update: (id, data) => api.put(`/specializations/${id}`, data),
  delete: (id) => api.delete(`/specializations/${id}`),
};

// Keep old APIs for backward compatibility
export const technologiesAPI = jobsAPI;
export const specializationsAPI = positionsAPI;

export const gradesAPI = {
  list: () => api.get('/grades'),
  create: (data) => api.post('/grades', data),
  update: (id, data) => api.put(`/grades/${id}`, data),
  delete: (id) => api.delete(`/grades/${id}`),
};

export const levelsAPI = {
  list: () => api.get('/levels'),
  create: (data) => api.post('/levels', data),
  update: (id, data) => api.put(`/levels/${id}`, data),
  delete: (id) => api.delete(`/levels/${id}`),
};

export const settingsAPI = {
  get: () => api.get('/settings'),
  update: (data) => api.put('/settings', data),
};

export default api;
