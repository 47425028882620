import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { employeesAPI, jobsAPI, positionsAPI, gradesAPI, levelsAPI } from '../../services/api';
import { useToast } from '../../contexts/ToastContext';
import api from '../../services/api';

const EmployeeForm = () => {
  const { id } = useParams();
  const isEditMode = !!id;

  const initialFormData = {
    name: '',
    technologyId: '',
    specializationId: '',
    gradeId: '',
    levelId: '',
    yearsExperience: 0,
    yearsWithCompany: 0,
    offshoreProject: false,
    actingAsDifferentGrade: false,
    pillar: false,
    remote: false,
    customMultipliers: []
  };

  const [formData, setFormData] = useState(initialFormData);
  const [jobs, setJobs] = useState([]);
  const [positions, setPositions] = useState([]);
  const [grades, setGrades] = useState([]);
  const [levels, setLevels] = useState([]);
  const [loading, setLoading] = useState(false);
  const [calculatedSalary, setCalculatedSalary] = useState(null);
  const [benefits, setBenefits] = useState([]);
  const [totalBenefits, setTotalBenefits] = useState(0);
  const [customMultiplierSettings, setCustomMultiplierSettings] = useState([]);

  const navigate = useNavigate();
  const { showToast } = useToast();

  const fetchPositions = useCallback(async (jobId) => {
    if (!jobId) {
      setPositions([]);
      return;
    }

    try {
      setLoading(true);
      const response = await positionsAPI.list(jobId);
      setPositions(response.data.data || []);
    } catch (error) {
      console.error('Error fetching positions:', error);
      showToast('Failed to fetch positions', 'error');
      setPositions([]);
    } finally {
      setLoading(false);
    }
  }, [showToast]);

  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      const [jobsRes, gradesRes, levelsRes, settingsRes] = await Promise.all([
        jobsAPI.list(),
        gradesAPI.list(),
        levelsAPI.list(),
        api.get('/settings')
      ]);

      const jobsData = jobsRes?.data?.data || [];
      const gradesData = gradesRes?.data?.data || [];
      const levelsData = levelsRes?.data?.data || [];
      const settingsData = settingsRes?.data?.data;

      // Sort grades and levels by order
      const sortedGrades = gradesData.sort((a, b) => (a.order || 0) - (b.order || 0));
      const sortedLevels = levelsData.sort((a, b) => (a.order || 0) - (b.order || 0));

      setJobs(jobsData);
      setGrades(sortedGrades);
      setLevels(sortedLevels);

      // Parse benefits
      try {
        const parsedBenefits = settingsData?.benefits ? 
          (typeof settingsData.benefits === 'string' ? JSON.parse(settingsData.benefits) : settingsData.benefits) 
          : [];
        setBenefits(parsedBenefits);
        setTotalBenefits(parsedBenefits.reduce((sum, benefit) => sum + Number(benefit.amount), 0));

        // Parse custom multipliers from settings
        const parsedCustomMultipliers = settingsData?.customMultipliers ? 
          (typeof settingsData.customMultipliers === 'string' ? JSON.parse(settingsData.customMultipliers) : settingsData.customMultipliers) 
          : [];
        setCustomMultiplierSettings(parsedCustomMultipliers);

        // Initialize form data custom multipliers if not in edit mode
        if (!isEditMode) {
          setFormData(prev => ({
            ...prev,
            customMultipliers: new Array(parsedCustomMultipliers.length).fill(false)
          }));
        }
      } catch (error) {
        console.error('Error parsing settings:', error);
      }

      // If there's a job selected, fetch its positions
      if (formData.technologyId) {
        await fetchPositions(formData.technologyId);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      showToast('Failed to fetch data', 'error');
    } finally {
      setLoading(false);
    }
  }, [formData.technologyId, fetchPositions, showToast, isEditMode]);

  const fetchEmployee = useCallback(async () => {
    try {
      setLoading(true);
      const response = await employeesAPI.get(id);
      const employee = response.data.data;
      
      setFormData({
        name: employee.name,
        technologyId: employee.technologyId,
        specializationId: employee.specializationId,
        gradeId: employee.gradeId,
        levelId: employee.levelId,
        yearsExperience: employee.yearsExperience || 0,
        yearsWithCompany: employee.yearsWithCompany || 0,
        offshoreProject: employee.offshoreProject,
        actingAsDifferentGrade: employee.actingAsDifferentGrade,
        pillar: employee.pillar,
        remote: employee.remote,
        customMultipliers: JSON.parse(employee.customMultipliers || '[]')
      });

      setCalculatedSalary(employee.calculatedSalary);

      // Fetch positions for the selected job
      if (employee.technologyId) {
        await fetchPositions(employee.technologyId);
      }
    } catch (error) {
      console.error('Error fetching employee:', error);
      showToast('Failed to fetch employee', 'error');
      navigate('/dashboard');
    } finally {
      setLoading(false);
    }
  }, [id, fetchPositions, showToast, navigate]);

  useEffect(() => {
    fetchData();
    if (isEditMode) {
      fetchEmployee();
    }
  }, [isEditMode, fetchData, fetchEmployee]);

  const handleJobChange = async (e) => {
    const jobId = e.target.value;
    setFormData(prev => ({ ...prev, technologyId: jobId, specializationId: '' }));
    await fetchPositions(jobId);
  };

  const handleCustomMultiplierChange = (index) => {
    setFormData(prev => {
      const newMultipliers = [...prev.customMultipliers];
      newMultipliers[index] = !newMultipliers[index];
      return { ...prev, customMultipliers: newMultipliers };
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);
      const payload = {
        ...formData,
        technologyId: Number(formData.technologyId),
        specializationId: Number(formData.specializationId),
        gradeId: Number(formData.gradeId),
        levelId: Number(formData.levelId),
        yearsExperience: Number(formData.yearsExperience),
        yearsWithCompany: Number(formData.yearsWithCompany),
        customMultipliers: JSON.stringify(formData.customMultipliers)
      };

      if (isEditMode) {
        await employeesAPI.update(id, payload);
        showToast('Employee updated successfully');
      } else {
        await employeesAPI.create(payload);
        showToast('Employee created successfully');
      }
      navigate('/dashboard');
    } catch (error) {
      console.error('Error saving employee:', error);
      showToast(error.response?.data?.message || 'Operation failed', 'error');
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="p-6">
      <div className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">{isEditMode ? 'Edit Employee' : 'Add New Employee'}</h1>
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <form onSubmit={handleSubmit} className="bg-white rounded-lg shadow p-6">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Name
              </label>
              <input
                type="text"
                value={formData.name}
                onChange={(e) => setFormData(prev => ({ ...prev, name: e.target.value }))}
                className="w-full border rounded-md p-2"
                required
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Job
              </label>
              <select
                value={formData.technologyId}
                onChange={handleJobChange}
                className="w-full border rounded-md p-2"
                required
              >
                <option value="">Select Job</option>
                {jobs.map(job => (
                  <option key={job.id} value={job.id}>{job.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Position
              </label>
              <select
                value={formData.specializationId}
                onChange={(e) => setFormData(prev => ({ ...prev, specializationId: e.target.value }))}
                className="w-full border rounded-md p-2"
                required
                disabled={!formData.technologyId || loading}
              >
                <option value="">Select Position</option>
                {positions.map(position => (
                  <option key={position.id} value={position.id}>{position.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Grade
              </label>
              <select
                value={formData.gradeId}
                onChange={(e) => setFormData(prev => ({ ...prev, gradeId: e.target.value }))}
                className="w-full border rounded-md p-2"
                required
              >
                <option value="">Select Grade</option>
                {grades.map(grade => (
                  <option key={grade.id} value={grade.id}>{grade.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Level
              </label>
              <select
                value={formData.levelId}
                onChange={(e) => setFormData(prev => ({ ...prev, levelId: e.target.value }))}
                className="w-full border rounded-md p-2"
                required
              >
                <option value="">Select Level</option>
                {levels.map(level => (
                  <option key={level.id} value={level.id}>{level.name}</option>
                ))}
              </select>
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Years of Experience
              </label>
              <input
                type="number"
                value={formData.yearsExperience}
                onChange={(e) => setFormData(prev => ({ ...prev, yearsExperience: e.target.value }))}
                className="w-full border rounded-md p-2"
                required
                min="0"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Years with Company
              </label>
              <input
                type="number"
                value={formData.yearsWithCompany}
                onChange={(e) => setFormData(prev => ({ ...prev, yearsWithCompany: e.target.value }))}
                className="w-full border rounded-md p-2"
                required
                min="0"
              />
            </div>
          </div>

          <div className="mt-6 space-y-4">
            <div className="flex items-center">
              <input
                type="checkbox"
                id="offshoreProject"
                checked={formData.offshoreProject}
                onChange={(e) => setFormData(prev => ({ ...prev, offshoreProject: e.target.checked }))}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="offshoreProject" className="ml-2 block text-sm text-gray-900">
                Base on Project
              </label>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="actingAsDifferentGrade"
                checked={formData.actingAsDifferentGrade}
                onChange={(e) => setFormData(prev => ({ ...prev, actingAsDifferentGrade: e.target.checked }))}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="actingAsDifferentGrade" className="ml-2 block text-sm text-gray-900">
                Acting as Different Grade
              </label>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="pillar"
                checked={formData.pillar}
                onChange={(e) => setFormData(prev => ({ ...prev, pillar: e.target.checked }))}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="pillar" className="ml-2 block text-sm text-gray-900">
                Pillar
              </label>
            </div>

            <div className="flex items-center">
              <input
                type="checkbox"
                id="remote"
                checked={formData.remote}
                onChange={(e) => setFormData(prev => ({ ...prev, remote: e.target.checked }))}
                className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
              />
              <label htmlFor="remote" className="ml-2 block text-sm text-gray-900">
                Remote
              </label>
            </div>

            {/* Custom Multipliers Section */}
            {customMultiplierSettings.length > 0 && (
              <div className="mt-6">
                <h3 className="text-lg font-medium text-gray-900 mb-4">Custom Multipliers</h3>
                <div className="space-y-4">
                  {customMultiplierSettings.map((multiplier, index) => (
                    <div key={index} className="flex items-center justify-between">
                      <div className="flex items-center">
                        <input
                          type="checkbox"
                          id={`multiplier-${index}`}
                          checked={formData.customMultipliers[index] || false}
                          onChange={() => handleCustomMultiplierChange(index)}
                          className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label htmlFor={`multiplier-${index}`} className="ml-2 block text-sm text-gray-900">
                          {multiplier.title}
                        </label>
                      </div>
                      <span className="text-sm text-gray-500">
                        {(multiplier.multiplier * 100).toFixed(1)}%
                      </span>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>

          <div className="mt-6 flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => navigate('/dashboard')}
              className="px-4 py-2 border rounded-md hover:bg-gray-50"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={loading}
              className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 disabled:opacity-50"
            >
              {isEditMode ? 'Update' : 'Create'} Employee
            </button>
          </div>
        </form>

        {/* Salary and Benefits Information */}
        {isEditMode && calculatedSalary && (
          <div className="bg-white rounded-lg shadow p-6">
            <h2 className="text-xl font-semibold mb-6">Salary Information</h2>
            
            {/* Total Package Box */}
            <div className="bg-blue-50 p-4 rounded-lg mb-6">
              <h3 className="text-lg font-semibold text-blue-900 mb-2">Total Package</h3>
              <div className="grid grid-cols-2 gap-4">
                <div>
                  <span className="font-medium text-blue-700">Base Salary:</span>
                  <span className="ml-2">${calculatedSalary.toLocaleString()}</span>
                </div>
                <div>
                  <span className="font-medium text-blue-700">Total Benefits:</span>
                  <span className="ml-2">${totalBenefits.toLocaleString()}</span>
                </div>
                <div className="col-span-2 pt-2 border-t border-blue-200">
                  <span className="font-medium text-blue-900">Total Package Value:</span>
                  <span className="ml-2">${(calculatedSalary + totalBenefits).toLocaleString()}</span>
                </div>
              </div>
            </div>

            {/* Benefits Box */}
            <div className="bg-green-50 p-4 rounded-lg">
              <h3 className="text-lg font-semibold text-green-900 mb-2">Benefits Breakdown</h3>
              <div className="grid gap-2">
                {benefits.map((benefit, index) => (
                  <div key={index} className="flex justify-between items-center">
                    <span className="font-medium text-green-700">{benefit.name}:</span>
                    <span>${Number(benefit.amount).toLocaleString()}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Custom Multipliers Box */}
            {customMultiplierSettings.length > 0 && formData.customMultipliers.some(m => m) && (
              <div className="bg-purple-50 p-4 rounded-lg mt-6">
                <h3 className="text-lg font-semibold text-purple-900 mb-2">Active Custom Multipliers</h3>
                <div className="grid gap-2">
                  {customMultiplierSettings.map((multiplier, index) => (
                    formData.customMultipliers[index] && (
                      <div key={index} className="flex justify-between items-center">
                        <span className="font-medium text-purple-700">{multiplier.title}:</span>
                        <span>{(multiplier.multiplier * 100).toFixed(1)}%</span>
                      </div>
                    )
                  ))}
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default EmployeeForm;
