import React, { useState, useEffect } from 'react';
import { jobsAPI, positionsAPI, gradesAPI, levelsAPI, calculatorAPI, default as api } from '../../services/api';
import { useToast } from '../../contexts/ToastContext';

const STORAGE_KEY = 'calculator_form_data';

const initialFormData = {
    technologyId: '',
    specializationId: '',
    gradeId: '',
    levelId: '',
    yearsExperience: 0,
    yearsWithCompany: 0,
    offshoreProject: false,
    actingAsDifferentGrade: false,
    pillar: false,
    remote: false,
    customMultipliers: []
};

const Calculator = () => {
    const { showToast } = useToast();
    const [formData, setFormData] = useState(() => {
        try {
            const savedData = localStorage.getItem(STORAGE_KEY);
            return savedData ? JSON.parse(savedData) : initialFormData;
        } catch (error) {
            console.error('Error parsing saved form data:', error);
            return initialFormData;
        }
    });

    const [jobs, setJobs] = useState([]);
    const [positions, setPositions] = useState([]);
    const [grades, setGrades] = useState([]);
    const [levels, setLevels] = useState([]);
    const [settings, setSettings] = useState(null);
    const [calculatedSalary, setCalculatedSalary] = useState(null);
    const [salaryDetails, setSalaryDetails] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState({});

    useEffect(() => {
        try {
            localStorage.setItem(STORAGE_KEY, JSON.stringify(formData));
        } catch (error) {
            console.error('Error saving form data:', error);
        }
    }, [formData]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const [jobsResponse, gradesResponse, levelsResponse, settingsResponse] = await Promise.all([
                    jobsAPI.list(),
                    gradesAPI.list(),
                    levelsAPI.list(),
                    api.get('/settings')
                ]);

                const jobsData = jobsResponse.data?.data || [];
                const gradesData = gradesResponse.data?.data || [];
                const levelsData = levelsResponse.data?.data || [];
                const settingsData = settingsResponse.data?.data;

                setJobs(jobsData);
                setGrades(gradesData);
                setLevels(levelsData);
                setSettings(settingsData);

                if (settingsData?.customMultipliers) {
                    const customMultipliersArray = settingsData.customMultipliers;
                    setFormData(prev => ({
                        ...prev,
                        customMultipliers: prev.customMultipliers.length ? prev.customMultipliers : new Array(customMultipliersArray.length).fill(false)
                    }));
                }
            } catch (error) {
                console.error('Error fetching data:', error);
                showToast('Error loading form data', 'error');
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [showToast]);

    useEffect(() => {
        if (formData.technologyId) {
            const fetchPositions = async () => {
                try {
                    const response = await positionsAPI.list(formData.technologyId);
                    const positionsData = response.data?.data || [];
                    setPositions(positionsData);
                } catch (error) {
                    console.error('Error fetching positions:', error);
                    showToast('Error loading positions', 'error');
                }
            };

            fetchPositions();
        } else {
            setPositions([]);
            setFormData(prev => ({ ...prev, specializationId: '' }));
        }
    }, [formData.technologyId, showToast]);

    const validateForm = () => {
        const newErrors = {};

        if (!formData.technologyId) newErrors.technologyId = 'Job is required';
        if (!formData.specializationId) newErrors.specializationId = 'Position is required';
        if (!formData.gradeId) newErrors.gradeId = 'Grade is required';
        if (!formData.levelId) newErrors.levelId = 'Level is required';
        if (formData.yearsExperience === '') newErrors.yearsExperience = 'Years of Experience is required';
        if (formData.yearsWithCompany === '') newErrors.yearsWithCompany = 'Years with Company is required';

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleInputChange = (e) => {
        const { name, value, type, checked } = e.target;
        const newValue = type === 'checkbox' ? checked : value;
        
        setFormData(prev => ({
            ...prev,
            [name]: newValue
        }));

        if (errors[name]) {
            setErrors(prev => ({
                ...prev,
                [name]: undefined
            }));
        }
    };

    const handleCustomMultiplierChange = (index, checked) => {
        setFormData(prev => {
            const newMultipliers = [...prev.customMultipliers];
            newMultipliers[index] = checked;
            return { ...prev, customMultipliers: newMultipliers };
        });
    };

    const handleReset = () => {
        localStorage.removeItem(STORAGE_KEY);
        setFormData(initialFormData);
        setCalculatedSalary(null);
        setSalaryDetails(null);
        setErrors({});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!validateForm()) {
            showToast('Please fill in all required fields', 'error');
            return;
        }

        setLoading(true);

        try {
            const payload = {
                ...formData,
                customMultipliers: JSON.stringify(formData.customMultipliers)
            };
            const response = await calculatorAPI.calculate(payload);
            const result = response.data?.data || response.data;
            setCalculatedSalary(result.calculatedSalary);
            setSalaryDetails(result.details);
            showToast('Salary calculated successfully', 'success');
        } catch (error) {
            console.error('Error calculating salary:', error);
            showToast(error.response?.data?.message || 'Error calculating salary', 'error');
        } finally {
            setLoading(false);
        }
    };

    const customMultipliersArray = settings?.customMultipliers || [];
    let benefits = [];
    let totalBenefits = 0;

    try {
        benefits = settings?.benefits ? (typeof settings.benefits === 'string' ? JSON.parse(settings.benefits) : settings.benefits) : [];
        totalBenefits = Array.isArray(benefits) ? benefits.reduce((sum, benefit) => sum + Number(benefit.amount), 0) : 0;
    } catch (error) {
        console.error('Error parsing benefits:', error);
    }

    const accumulatedCost = calculatedSalary ? calculatedSalary + totalBenefits : null;

    if (loading && !calculatedSalary) {
        return (
            <div className="flex justify-center items-center h-64">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
            </div>
        );
    }

    return (
        <div className="p-6">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-2xl font-bold">Salary Calculator</h1>
                <button
                    onClick={handleReset}
                    className="px-4 py-2 bg-gray-600 text-white rounded-md hover:bg-gray-700 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                    Reset
                </button>
            </div>

            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                {/* Calculator Form */}
                <div className="bg-white rounded-lg shadow p-6">
                    <h2 className="text-xl font-semibold mb-6">Calculate Salary</h2>
                    <form onSubmit={handleSubmit} className="space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            {/* Job */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Job</label>
                                <select
                                    name="technologyId"
                                    value={formData.technologyId}
                                    onChange={handleInputChange}
                                    className={`w-full border rounded-md p-2 ${errors.technologyId ? 'border-red-300' : ''}`}
                                >
                                    <option value="">Select Job</option>
                                    {jobs.map(job => (
                                        <option key={job.id} value={job.id}>{job.name}</option>
                                    ))}
                                </select>
                                {errors.technologyId && (
                                    <p className="mt-1 text-sm text-red-600">{errors.technologyId}</p>
                                )}
                            </div>

                            {/* Position */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Position</label>
                                <select
                                    name="specializationId"
                                    value={formData.specializationId}
                                    onChange={handleInputChange}
                                    className={`w-full border rounded-md p-2 ${errors.specializationId ? 'border-red-300' : ''}`}
                                    disabled={!formData.technologyId}
                                >
                                    <option value="">Select Position</option>
                                    {positions.map(position => (
                                        <option key={position.id} value={position.id}>{position.name}</option>
                                    ))}
                                </select>
                                {errors.specializationId && (
                                    <p className="mt-1 text-sm text-red-600">{errors.specializationId}</p>
                                )}
                            </div>

                            {/* Grade */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Grade</label>
                                <select
                                    name="gradeId"
                                    value={formData.gradeId}
                                    onChange={handleInputChange}
                                    className={`w-full border rounded-md p-2 ${errors.gradeId ? 'border-red-300' : ''}`}
                                >
                                    <option value="">Select Grade</option>
                                    {grades.map(grade => (
                                        <option key={grade.id} value={grade.id}>{grade.name}</option>
                                    ))}
                                </select>
                                {errors.gradeId && (
                                    <p className="mt-1 text-sm text-red-600">{errors.gradeId}</p>
                                )}
                            </div>

                            {/* Level */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Level</label>
                                <select
                                    name="levelId"
                                    value={formData.levelId}
                                    onChange={handleInputChange}
                                    className={`w-full border rounded-md p-2 ${errors.levelId ? 'border-red-300' : ''}`}
                                >
                                    <option value="">Select Level</option>
                                    {levels.map(level => (
                                        <option key={level.id} value={level.id}>{level.name}</option>
                                    ))}
                                </select>
                                {errors.levelId && (
                                    <p className="mt-1 text-sm text-red-600">{errors.levelId}</p>
                                )}
                            </div>

                            {/* Years of Experience */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Years of Experience</label>
                                <input
                                    type="number"
                                    name="yearsExperience"
                                    value={formData.yearsExperience}
                                    onChange={handleInputChange}
                                    min="0"
                                    className={`w-full border rounded-md p-2 ${errors.yearsExperience ? 'border-red-300' : ''}`}
                                />
                                {errors.yearsExperience && (
                                    <p className="mt-1 text-sm text-red-600">{errors.yearsExperience}</p>
                                )}
                            </div>

                            {/* Years with Company */}
                            <div>
                                <label className="block text-sm font-medium text-gray-700">Years with Company</label>
                                <input
                                    type="number"
                                    name="yearsWithCompany"
                                    value={formData.yearsWithCompany}
                                    onChange={handleInputChange}
                                    min="0"
                                    className={`w-full border rounded-md p-2 ${errors.yearsWithCompany ? 'border-red-300' : ''}`}
                                />
                                {errors.yearsWithCompany && (
                                    <p className="mt-1 text-sm text-red-600">{errors.yearsWithCompany}</p>
                                )}
                            </div>
                        </div>

                        {/* Checkboxes */}
                        <div className="bg-gray-50 p-4 rounded-lg">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                <div className="space-y-4">
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="offshoreProject"
                                            checked={formData.offshoreProject}
                                            onChange={handleInputChange}
                                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                        />
                                        <span className="ml-2 text-sm text-gray-900">Base on Project</span>
                                    </label>

                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="actingAsDifferentGrade"
                                            checked={formData.actingAsDifferentGrade}
                                            onChange={handleInputChange}
                                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                        />
                                        <span className="ml-2 text-sm text-gray-900">Acting as Different Grade</span>
                                    </label>
                                </div>

                                <div className="space-y-4">
                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="pillar"
                                            checked={formData.pillar}
                                            onChange={handleInputChange}
                                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                        />
                                        <span className="ml-2 text-sm text-gray-900">Pillar</span>
                                    </label>

                                    <label className="flex items-center">
                                        <input
                                            type="checkbox"
                                            name="remote"
                                            checked={formData.remote}
                                            onChange={handleInputChange}
                                            className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                        />
                                        <span className="ml-2 text-sm text-gray-900">Remote</span>
                                    </label>
                                </div>
                            </div>

                            {/* Custom Multipliers */}
                            {customMultipliersArray.length > 0 && (
                                <div className="mt-4">
                                    <h3 className="text-sm font-medium text-gray-700 mb-2">Custom Multipliers</h3>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        {customMultipliersArray.map((multiplier, index) => (
                                            <label key={multiplier.id} className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    checked={formData.customMultipliers[index] || false}
                                                    onChange={(e) => handleCustomMultiplierChange(index, e.target.checked)}
                                                    className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                                                />
                                                <span className="ml-2 text-sm text-gray-900">{multiplier.title}</span>
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="flex justify-between items-center">
                            <button
                                type="submit"
                                disabled={loading}
                                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 disabled:opacity-50"
                            >
                                {loading ? 'Calculating...' : 'Calculate Salary'}
                            </button>

                            {calculatedSalary !== null && (
                                <div className="text-xl font-bold">
                                    Calculated Salary: ${calculatedSalary.toLocaleString()}
                                </div>
                            )}
                        </div>
                    </form>
                </div>

                {/* Calculation Details */}
                {salaryDetails && (
                    <div className="bg-white rounded-lg shadow p-6">
                        <h2 className="text-xl font-semibold mb-6">Calculation Details</h2>
                        
                        {/* Total Cost Box */}
                        <div className="bg-blue-50 p-4 rounded-lg mb-6">
                            <h3 className="text-lg font-semibold text-blue-900 mb-2">Total Package</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <span className="font-medium text-blue-700">Base Salary:</span>
                                    <span className="ml-2">${calculatedSalary.toLocaleString()}</span>
                                </div>
                                <div>
                                    <span className="font-medium text-blue-700">Total Benefits:</span>
                                    <span className="ml-2">${totalBenefits.toLocaleString()}</span>
                                </div>
                                <div className="col-span-2 pt-2 border-t border-blue-200">
                                    <span className="font-medium text-blue-900">Total Package Value:</span>
                                    <span className="ml-2">${accumulatedCost.toLocaleString()}</span>
                                </div>
                            </div>
                        </div>

                        {/* Benefits Box */}
                        <div className="bg-green-50 p-4 rounded-lg mb-6">
                            <h3 className="text-lg font-semibold text-green-900 mb-2">Benefits Breakdown</h3>
                            <div className="grid gap-2">
                                {benefits.map((benefit, index) => (
                                    <div key={index} className="flex justify-between items-center">
                                        <span className="font-medium text-green-700">{benefit.name}:</span>
                                        <span>${Number(benefit.amount).toLocaleString()}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="bg-gray-50 p-6 rounded-lg">
                            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                                <div className="space-y-4">
                                    <div>
                                        <span className="font-medium text-gray-700">Base Salary:</span>
                                        <span className="ml-2">${salaryDetails.baseSalary.toLocaleString()}</span>
                                    </div>
                                    <div>
                                        <span className="font-medium text-gray-700">Adjustment Factor:</span>
                                        <span className="ml-2">{(salaryDetails.adjustmentFactor * 100).toFixed(1)}%</span>
                                    </div>
                                    <div>
                                        <span className="font-medium text-gray-700">Experience Multiplier:</span>
                                        <span className="ml-2">{(salaryDetails.experienceMultiplier * 100).toFixed(1)}%</span>
                                    </div>
                                    <div>
                                        <span className="font-medium text-gray-700">Loyalty Multiplier:</span>
                                        <span className="ml-2">{(salaryDetails.loyaltyMultiplier * 100).toFixed(1)}%</span>
                                    </div>
                                </div>
                                <div className="space-y-4">
                                    <div>
                                        <span className="font-medium text-gray-700">Base Project Multiplier:</span>
                                        <span className="ml-2">{(salaryDetails.offshoreMultiplier * 100).toFixed(1)}%</span>
                                    </div>
                                    <div>
                                        <span className="font-medium text-gray-700">Acting As Multiplier:</span>
                                        <span className="ml-2">{(salaryDetails.actingAsMultiplier * 100).toFixed(1)}%</span>
                                    </div>
                                    <div>
                                        <span className="font-medium text-gray-700">Pillar Multiplier:</span>
                                        <span className="ml-2">{(salaryDetails.pillarMultiplier * 100).toFixed(1)}%</span>
                                    </div>
                                    <div>
                                        <span className="font-medium text-gray-700">Remote Multiplier:</span>
                                        <span className="ml-2">{(salaryDetails.remoteMultiplier * 100).toFixed(1)}%</span>
                                    </div>
                                </div>
                            </div>

                            {/* Custom Multipliers Details */}
                            {salaryDetails.customMultipliers && salaryDetails.customMultipliers.length > 0 && (
                                <div className="mt-6">
                                    <h3 className="text-sm font-medium text-gray-700 mb-2">Custom Multipliers</h3>
                                    <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                                        {salaryDetails.customMultipliers.map((multiplier, index) => (
                                            <div key={index}>
                                                <span className="font-medium text-gray-700">{multiplier.title}:</span>
                                                <span className="ml-2">{`${(multiplier.multiplier * 100).toFixed(1)}%`}</span>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Calculator;
