import React, { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';

const PermissionRoute = ({ children, requiredPermissions = [], requireAdmin = false }) => {
    const { user } = useAuth();
    const location = useLocation();

    useEffect(() => {
        console.log('PermissionRoute check:', {
            pathname: location.pathname,
            user: {
                id: user?.id,
                email: user?.email,
                role: user?.role,
                isAdmin: user?.isAdmin,
                permissions: user?.permissions
            },
            requiredPermissions,
            requireAdmin
        });
    }, [location.pathname, user, requiredPermissions, requireAdmin]);

    // Early return if no user
    if (!user) {
        console.log('No user found, redirecting to login');
        return <Navigate to="/login" state={{ from: location }} replace />;
    }

    // Admin users bypass all permission checks
    if (user.isAdmin) {
        console.log('User is admin, granting access');
        return children;
    }

    // Check admin requirement first
    if (requireAdmin && !user.isAdmin) {
        console.log('Admin access required but user is not admin, redirecting to dashboard');
        return <Navigate to="/dashboard" replace />;
    }

    // If no permissions required and not requiring admin, allow access
    if (requiredPermissions.length === 0 && !requireAdmin) {
        console.log('No permissions required, granting access');
        return children;
    }

    // Check if user has all required permissions
    const hasPermissions = requiredPermissions.every(permission => {
        const hasPermission = Boolean(user.permissions?.[permission]);
        console.log(`Checking permission ${permission}:`, {
            required: permission,
            userHas: hasPermission,
            allPermissions: user.permissions
        });
        return hasPermission;
    });

    if (!hasPermissions) {
        console.log('Permission check failed:', {
            path: location.pathname,
            required: requiredPermissions,
            userPermissions: user.permissions
        });
        return <Navigate to="/dashboard" replace />;
    }

    // Special handling for settings routes
    if (location.pathname.startsWith('/settings') && !user.permissions?.showSettings) {
        console.log('Settings access denied:', {
            path: location.pathname,
            showSettings: user.permissions?.showSettings
        });
        return <Navigate to="/dashboard" replace />;
    }

    console.log('Permission check passed for path:', location.pathname);
    return children;
};

// Prevent unnecessary re-renders
export default React.memo(PermissionRoute, (prevProps, nextProps) => {
    return (
        prevProps.requireAdmin === nextProps.requireAdmin &&
        prevProps.children === nextProps.children &&
        JSON.stringify(prevProps.requiredPermissions) === JSON.stringify(nextProps.requiredPermissions)
    );
});
